.software-development {

    .service-banner {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 10%;

        article {
            width: 40%;
            max-width: 500px;

            padding: 50px;

            p {
                margin-bottom: 40px;
            }
        }

        img {
            width: 40%;
        }
    }



    .feature {
        padding: 0% 8%;
        margin-bottom: 10%;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            padding: 0;


            li {
                width: 30%;
                min-width: 30%;
                margin-bottom: 5%;


            }
        }


    }

    .system-module {
        margin: 10% 0;
        display: flex;
        justify-content: center;

        ul {
            width: 80%;
            display: flex;
            flex-direction: column;

            li {
                display: flex;
                justify-content: space-evenly;
                margin-bottom: 6%;

                .title {
                    width: 30%;
                    text-align: center;

                    svg {
                        width: 100%;
                    }

                }

                .content {
                    width: 60%;
                    display: flex;
                    justify-content: space-around;

                    .content-module {
                        width: 45%;

                        hr {
                            margin-bottom: 16px;
                        }

                        ul {
                            padding: 0;

                            li {
                                display: flex;
                                justify-content: flex-start;

                                svg {
                                    margin-right: 7px;
                                }
                            }
                        }
                    }

                }
            }

            li:nth-child(2n) {

                .title {
                    order: 2;

                }

                .content {
                    order: 1;
                }
            }
        }
    }
}

@media screen and (max-width:1200px) {
    .software-development {

        .feature {

            ul {

                li {
                    width: 45%;
                    margin-bottom: 40px;

                }
            }


        }
    }
}

@media screen and (max-width:843px) {
    .software-development {

        .feature {

            ul {

                li {
                    width: 90%;
                }
            }


        }


        .system-module {
            margin: 10% 0;
            display: flex;
            justify-content: center;

            ul {
                width: 80%;
                display: flex;
                flex-direction: column;
                align-items: center;

                li {
                    display: flex;

                    flex-direction: column;

                    justify-content: space-evenly;
                    margin-bottom: 6%;

                    .title {
                        display: flex;
                        width: 100%;
                        text-align: left;
                        align-items: center;

                        svg {
                            width: 80%;

                        }

                        .title-title {
                            min-width: 200px;
                        }


                    }

                    .content {
                        width: 100%;
                        display: flex;
                        justify-content: space-around;

                        .content-module {
                            width: 45%;

                            hr {
                                margin-bottom: 16px;
                            }

                            ul {
                                padding: 0;
                                align-items: flex-start;

                                li {
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-start;

                                    svg {
                                        margin-right: 7px;
                                    }
                                }
                            }
                        }

                    }
                }

                li:nth-child(2n) {

                    .title {
                        display: flex;
                        flex-direction: row-reverse;
                        order: 1;
                        text-align: right;



                    }

                    .content {
                        order: 2;
                    }
                }
            }
        }
    }
}



@media screen and (max-width:768px) {
    .software-development {

        .service-banner {
            display: flex;
            flex-direction: column;

            align-items: center;
            margin-bottom: 10%;

            article {
                width: 80%;
                max-width: none;

                padding: 50px;

                p {
                    margin-bottom: 40px;
                    text-align: center;
                }
            }

            img {
                width: 80%;
            }
        }


    }
}