.news-wrapper {
    display: flex;
    flex-direction: column;

    .sort {
        padding-top: 50px;

        ul {
            display: flex;
            justify-content: center;

            li {
                padding: 1% 3%;
                color: $white--one;
                text-align: center;
                font-family: 'Noto Sans TC';
                font-size: 1.25rem;
                font-weight: 300;
                letter-spacing: 0.3rem;

                border: 1px solid $black;


                cursor: pointer;

                &:hover {
                    color: $orange;
                    border: 1px solid $orange;
                }
            }
        }
    }

    .news-container {

        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        width: 80%;
        justify-content: flex-start;
        padding: 0;
        padding: 50px 0px;
        align-self: center;
        color: $white--one;

        ul {
            width: 100%;

            li {
                border-bottom: 1px solid #888;
                display: flex;
                align-items: center;
                padding: 1% 2%;
                width: 100%;

                .title {
                    border: 1px solid $white--one ;
                    padding: 1% 2%;
                    white-space: nowrap;

                }

                .news-title {
                    flex-grow: 1;
                    max-width: 35%;
                }

                span {
                    margin-right: 5%;
                }

                p {
                    margin-right: 5%;
                    letter-spacing: 3.4px;

                    width: 50%;

                }

                a {
                    width: 100%;
                    display: flex;
                    align-items: center;
                }

                p {
                    flex-grow: 1;
                }

                .arrow {
                    width: 15%;
                    min-width: 25px;
                    display: flex;
                    justify-content: flex-end;

                    div {
                        width: 100%;
                        border-top: 1px solid $white--one ;
                    }

                    &::after {
                        content: '';
                        width: 6px;
                        height: 6px;
                        border: solid $white--one;
                        border-width: 1px 1px 0 0;
                        transform: translate(-90%, -50%) rotate(45deg);

                    }
                }

                &:hover {
                    color: $blue;
                    transition: 500ms linear;

                    .title {
                        transition: 500ms linear;
                        background-color: $blue;
                        color: $black;
                        border: 1px solid $blue ;

                    }

                    .arrow {
                        width: 15%;

                        div {
                            width: 25%;
                            transition: 500ms linear;
                            border-top: 1px solid $white--one ;
                        }
                    }
                }
            }
        }
    }

    .paginator {
        display: flex;
        justify-content: center;
        margin-bottom: 60px;

        ul {
            display: flex;
            width: 10%;
            justify-content: space-between;
            align-items: center;

            .left {
                width: 10px;
                height: 10px;
                display: block;


                border: solid $white--one;
                border-width: 2px 2px 0 0;
                transform: rotate(225deg);


            }

            li {
                cursor: pointer;

                &:hover {
                    color: $blue--one;
                }
            }

            .left {
                width: 10px;
                height: 10px;
                display: block;


                border: solid $white--one;
                border-width: 2px 2px 0 0;
                transform: rotate(225deg);


            }

            .right {
                width: 10px;
                height: 10px;
                display: block;


                border: solid $white--one;
                border-width: 2px 2px 0 0;
                transform: rotate(45deg);
            }

        }
    }
}