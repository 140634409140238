.introduction {
    .banner {
        height: auto;

        p {
            width: 60%;
            color: #DCDCDC;
            font-family: 'Bebas Neue';
            font-size: 3rem;
            line-height: 1.6;
            /* 132.759% */
            letter-spacing: 0.3rem;
            text-transform: capitalize;
        }
    }

    .brand-essence {

        padding-top: 50px;
        // outline: 1px solid red;
        display: flex;
        justify-content: center;

        p {
            width: 42%;
            text-align: center;

            .slogan {
                color: $white;
                font-family: 'Noto Sans TC';
                font-size: 5rem;
                font-weight: 500;
                letter-spacing: 1rem;
                margin-right: 5%;
                white-space: nowrap;
            }
        }


        article {
            width: 31%;

            align-self: flex-end;
            color: $white--one;
            font-family: 'Noto Sans TC';
            font-size: 17px;
            font-weight: 300;
            letter-spacing: 3.4px;

            p {
                width: 100%;
                margin-bottom: 36px;
                line-height: 2;
                text-align: justify;
            }
        }
    }

    .brands {
        margin: 200px 0px;

        ul {
            display: flex;
            justify-content: center;

            li {
                width: 25%;

                margin-bottom: 30px;
                // outline: 1px solid red;
                display: flex;
                flex-direction: column;

                .img-area-out {
                    width: 100%;
                    height: 25vh;
                    // outline: 1px solid blue;
                    border: 2px solid $white;
                    background-color: $white;

                    .img-area-in {
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        // outline: 1px solid blueviolet;
                        background-color: $black;
                        border-radius: 30px;

                        img {
                            max-height: 80%;
                            max-width: 80%;


                        }
                    }
                }

                section {

                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    flex-grow: 1;

                    article {
                        padding: 10%;
                        color: $white;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        flex-grow: 1;

                        h3 {
                            margin-bottom: 19px;
                            text-align: center;
                            font-family: 'Noto Sans TC';
                            font-size: 20px;
                            font-weight: 700;
                            line-height: 33px;
                            letter-spacing: 4px;
                        }

                        p {

                            color: $white--one;
                            font-family: 'Noto Sans TC';
                            font-size: 17px;
                            font-weight: 300;
                            line-height: 31px;
                            letter-spacing: 2.55px;
                            text-align: justify;
                        }
                    }

                    .more {
                        margin-top: 50px;

                        span {

                            font-size: 2rem;
                        }
                    }
                }

            }
        }

        p {
            white-space: pre-wrap;
        }
    }
}

@media screen and (max-width:1200px) {
    .introduction {
        .banner {
            p {
                width: 80%;
                color: #DCDCDC;
                font-family: 'Bebas Neue';
                font-size: 2rem;
                line-height: 1.6;
                /* 132.759% */
                letter-spacing: 0.2rem;
                text-transform: capitalize;
            }
        }

        .brand-essence {

            padding-top: 50px;
            // outline: 1px solid red;
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                width: 80%;
                text-align: left;

                .slogan {
                    color: $white;
                    font-family: 'Noto Sans TC';
                    font-size: 3rem;
                    font-weight: 500;
                    letter-spacing: 1rem;
                    margin-right: 5%;
                }
            }


            article {
                width: 80%;

                align-self: center;
                color: $white--one;
                font-family: 'Noto Sans TC';
                font-size: 17px;
                font-weight: 300;
                letter-spacing: 3.4px;
                margin-top: 20px;

                p {
                    width: 100%;
                    margin-bottom: 36px;
                }
            }
        }

        .brands {
            margin: 200px 0px;

            ul {
                display: flex;
                flex-direction: column;
                align-items: center;

                li {
                    width: 80%;
                    // outline: 1px solid red;

                    .img-area-out {
                        width: 100%;
                        height: 50vh;
                        // outline: 1px solid blue;
                        border: 2px solid $white;
                        background-color: $white;

                        .img-area-in {
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            // outline: 1px solid blueviolet;
                            background-color: $black;
                            border-radius: 30px;

                            img {

                                max-width: 440px;

                            }
                        }
                    }

                    article {
                        padding: 30px 40px;
                        color: $white;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        h3 {
                            margin-bottom: 19px;
                            text-align: center;
                            font-family: 'Noto Sans TC';
                            font-size: 20px;
                            font-weight: 700;
                            line-height: 33px;
                            letter-spacing: 4px;
                        }

                        p {

                            color: $white--one;
                            font-family: 'Noto Sans TC';
                            font-size: 17px;
                            font-weight: 300;
                            line-height: 31px;
                            letter-spacing: 2.55px;
                        }
                    }

                    .more {
                        margin-top: 50px;
                    }
                }
            }

            p {
                white-space: pre-wrap;
            }
        }
    }
}