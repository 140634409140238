button {
    background: none;
    border: none;
}

li {
    list-style: none;
}

* {
    margin: 0;
    padding: 0;

}

input {
    background: none;
    border: none;
}

textarea {
    background: none;
    border: none;
}

a {
    text-decoration: none;
    color: #FFFFFF;
}