// colors

$white: #FFFFFF;
$white--one: #DCDCDC;
$black: #1A1E22;
$black--one: #000000;

$grey--one: #ABABAB;
$grey--two: #585858;
$grey--three: #474747;

$orange: #F66A1A;

$blue: #66FCF1;
$blue--one: #A0F5EE;
$blue--two: #B6FBFF;

$transition--default: all 0.3s;