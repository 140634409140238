.lets-talk {
    padding: 30px;
    width: 100vw;
    height: 70vh;
    background-image: url(../../../../public/img/background/gradient-2.jpeg);
    background-size: cover;

    position: relative;
    z-index: 2;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    p {
        font-family: 'Noto Sans TC', sans-serif;
        color: $white;
        font-size: 28px;
        font-weight: 700;
        letter-spacing: 2.8px;
        margin-bottom: 8px;
    }

    .vertical-line {
        height: 144px;
        border: 1px solid $white;
        display: inline-block;
        margin-top: 40px;
    }

    .background-decoration {
        width: 100%;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translateX(-50%);
        font-family: 'Bebas Neue', sans-serif;
        font-size: 168px;
        text-align: left;
        letter-spacing: 16.8px;
        text-align: center;

        color: rgba(160, 245, 238, 0.40);
        opacity: 0.2;

        animation: breath 6s ease-in infinite;
    }

    @keyframes breath {
        0% {
            opacity: 0.2;
        }

        50% {
            opacity: 0.5;
        }

        100% {
            opacity: 0.2;
        }
    }
}

@media screen and (max-width: 768px) {
    .lets-talk {
        padding: 0;
        height: 70vh;
        position: relative;
        z-index: 2;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            font-size: 20px;
            letter-spacing: 2px;
        }

        .vertical-line {
            height: 100px;
            border: 1px solid $white;
            display: inline-block;
            margin-top: 0px;
        }


        .background-decoration {
            font-size: 60px;
            letter-spacing: 6px;
            top: 45%;
        }
    }
}