nav {
    padding: 34px 111px 26px;

    border-bottom: 1px solid $white--one;

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;



    img {
        width: 120px;
        height: 32px;
        z-index: 2;
    }

    .menu {
        display: flex;
        position: absolute;
        top: 22px;
        right: 90px;
        z-index: 300;

        .burger-cross {
            position: absolute;
            z-index: 2;
            transition: 1s ease-in;
        }

        .burger {
            transition: 1s linear;
        }

        button {
            padding: 8px 24px;
            margin-left: 23px;
            border: 1px solid $white;
            border-radius: 20px;
            color: $white;
            font-size: 17px;
            font-weight: 300;
            letter-spacing: 3.4px;

            &:hover {
                background-color: $orange;
                border: 1px solid $orange;

            }
        }
    }



}

.contact {
    width: 30vw;
    min-width: 300px;
    height: 100vh;
    padding: 36px 44px;

    background-color: $black--one;
    position: fixed;
    overflow-y: scroll;
    top: 0;
    right: 0;
    z-index: 999;


    // animation: slip-in 1s linear;
    // animation: slip-out 1s linear;

    visibility: hidden;
    transition: all 1s;
    transform: translateX(100%);



    &.active {
        transform: translateX(0%);
        visibility: visible;
    }

    .contact-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        p {
            color: $white;
            font-family: 'Noto Sans TC';
            font-size: 2rem;
            font-weight: 300;
            letter-spacing: 6.4px;
        }

        .burger-cross {
            width: 40px;
        }
    }

    .contact-form {
        padding-bottom: 100px;

        form {
            display: flex;
            flex-direction: column;

            .text-input-area {
                display: flex;
                justify-content: space-between;
            }

            .text-input {
                border-bottom: 1px solid $white--one;
                margin-bottom: 25px;
                padding: 10px;
                color: $white;
                font-family: 'Noto Sans TC';
                font-size: 17px;
                font-weight: 300;
                letter-spacing: 3.4px;

                &::placeholder {
                    color: $white;
                    font-family: 'Noto Sans TC';
                    font-size: 17px;
                    font-weight: 300;
                    letter-spacing: 3.4px;
                }

            }

            .short {
                width: 45%;
            }

            .long {
                width: 100%;
            }


            .radio {
                display: flex;
                flex-direction: column;

                margin-top: 20px;

                p {
                    margin-bottom: 10px;
                }

                div {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;


                    label {
                        width: 44%;
                        color: $white;
                        padding: 14px;
                        border: 1px solid $white--one;
                        margin-bottom: 10px;


                        input {
                            margin: 5px;

                            &::placeholder {
                                color: $white;
                                font-family: 'Noto Sans TC';
                                font-size: 17px;
                                font-weight: 300;
                                letter-spacing: 3.4px;
                            }
                        }
                    }
                }
            }

            span {
                margin-top: 20px;
            }

            textarea {
                margin-top: 20px;
                padding: 10px;
                width: 100%;
                height: 200px;

                resize: none;
                border: 1px solid $white;

                color: $white;
                font-family: 'Noto Sans TC';
                font-size: 17px;
                font-weight: 300;
                letter-spacing: 3.4px;

                &::placeholder {
                    color: $white;
                    font-family: 'Noto Sans TC';
                    font-size: 17px;
                    font-weight: 300;
                    letter-spacing: 3.4px;
                }
            }

            button {
                margin-top: 36px;
                align-self: center;
            }


        }
    }




}

// .slip-in {
//     transform: translateX(0);
//     transition: all 3s linear;

// }

// .slip-out {
//     transform: translateX(100%);
//     transition: all 3s linear;

// }

// @keyframes slip-in {
//     0% {
//         transform: translateX(0%);
//         // right: -30%;
//     }

//     100% {
//         transform: translateX(100%);
//         right: 0;
//     }
// }

// @keyframes slip-out {
//     0% {
//         transform: translateX(100%);
//         // right: 0;
//     }

//     100% {
//         transform: translateX(0%);
//         // right: -30%;
//         // 
//     }
// }

.menu-view {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: $black--one;

    overflow-y: scroll;

    // animation: showup 800ms ease-in;

    opacity: 0;
    visibility: hidden;
    transition: all 0.6s linear;

    // @keyframes showup {
    //     0% {
    //         opacity: 0;
    //     }

    //     100% {
    //         opacity: 1;
    //     }
    // }
    &.active {
        opacity: 1;
        visibility: visible;
    }

    ul {
        color: $white;
        width: 30%;
        min-width: 150px;
        // outline: 1px solid blueviolet;

        li {
            // outline: 1px solid red;
            width: 100%;
            margin-bottom: 7px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;


            .accordion {
                width: 100%;
                min-width: 300px;
                font-family: 'Noto Sans TC';
                font-size: 2.3rem;

                font-weight: 300;
                letter-spacing: 0.4rem;


                display: flex;
                align-items: center;

                span {
                    font-size: 2.625rem;
                    font-family: 'Bebas Neue';
                    letter-spacing: 0.2625rem;
                    margin-right: 20px;


                }

                a {
                    color: $white;

                    &:hover {
                        color: $blue;
                    }
                }

                .plus {

                    margin-left: 30px;
                    transition: 500ms linear;

                    width: 20px;
                    height: 20px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    div {
                        width: 15px;
                        height: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }


                }
            }

            ul {
                // outline: 1px solid tomato;
                transition: height 500ms linear;

                overflow: hidden;

                li {
                    font-family: 'Noto Sans TC';
                    font-size: 1.5rem;
                    font-weight: 300;
                    letter-spacing: 0.3rem;
                    padding: 9px 24px;
                    transition: opacity 500ms linear;

                    &:hover {
                        color: $blue--one;
                    }
                }
            }

            &:hover {
                .accordion {
                    border-bottom: 1px solid $white;
                    transition: 300ms linear;
                    color: $blue--one;
                }
            }

        }
    }

    .menu-lists {
        margin: 10% 30%;
    }
}

@media screen and (max-width:768px) {
    nav {
        padding: 0px 12px;

        img {
            margin: 12px;

            width: 57px;
            height: auto;
        }

        .menu {
            top: 10px;
            right: 20px;

            svg {
                width: 20px;
                height: 20px;
            }

            button {
                display: none;
            }
        }
    }

    // 手機版menu
    .menu-view {
        overflow-y: scroll;

        opacity: 0;
        visibility: hidden;
        transition: all 0.6s linear;
        z-index: 30;

        &.active {
            opacity: 1;
            visibility: visible;
            // transition-delay: 0.3s;
        }

        // 電視特效
        // &::before {
        //     content: '';
        //     width: 50%;
        //     height: 100vh;
        //     position: fixed;
        //     top: 0;
        //     left: 50%;
        //     z-index: 29;
        //     background-color: blue;
        //     transition: all 0.1s ease-out;


        // }

        // &::after {
        //     content: '';
        // }



        ul {
            li {
                .accordion {
                    min-width: 250px;
                }
            }
        }

        .menu-lists {
            margin: 10% 20%;
        }
    }
}