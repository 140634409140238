.process {
    .main {
        background-image: url(../../../../public/img/background/gradient-5.jpeg);
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;

        margin-top: 200px;

        position: relative;

        ul {
            width: 50%;
            padding: 10% 0px;
            display: flex;
            flex-wrap: wrap;
            display: flex;
            justify-content: space-around;
            gap: 10%;

            li {
                width: 40%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 100px;

                .f784 {
                    border-bottom: 1px solid $white;
                }

                p {
                    margin-top: 25px;
                }
            }
        }

        .step-dec {
            background-image: url(../../../../public/img/background/gradient-6.png);
            background-size: cover;
            background-position: center;
            width: 20%;
            height: 50vw;

            position: absolute;
            top: -200px;
            right: 0;



            span {
                font-family: 'Bebas Neue', sans-serif;
                font-size: 10rem;
                letter-spacing: 1rem;
                text-transform: capitalize;

                color: $black;

                transform: rotate(90deg);
                position: absolute;
                bottom: 86px;
                left: -81px;
            }
        }
    }
}

@media screen and (max-width:1200px) {
    .process {
        .main {
            ul {
                li {
                    width: 45%;
                }
            }
        }
    }
}

@media screen and (max-width:768px) {
    .process {
        .main {
            width: 100%;

            display: flex;
            justify-content: center;

            margin-top: 10%;

            position: relative;

            ul {
                width: 80%;
                padding: 0px;
                display: flex;
                flex-wrap: wrap;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 10%;
                margin-bottom: 50px;

                li {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 100px;

                    .f784 {
                        border-bottom: 1px solid $white;
                    }

                    p {
                        margin-top: 25px;
                    }
                }
            }

            .step-dec {
                display: none;



                span {
                    font-family: 'Bebas Neue', sans-serif;
                    font-size: 10rem;
                    letter-spacing: 1rem;
                    text-transform: capitalize;

                    color: $black;

                    transform: rotate(90deg);
                    position: absolute;
                    bottom: 86px;
                    left: -81px;
                }
            }
        }
    }

}