.marquee-container-img {
    // margin-top: 100px;
    margin-bottom: 4%;

    overflow: hidden;

    .marquee-content-img {

        width: 300vw;
        display: flex;
        white-space: nowrap;
        justify-content: space-around;

        transform: translateX(-100vw);
        // gap: 20px;

        .marquee-img-wrapper {
            // margin-right: 100px;
            width: 5vw;
            aspect-ratio: 1.6;

            opacity: 0.6;
            //    flex-grow: 1;

            img {
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;

            }
        }
    }

    .left {
        animation: marquee-img-left 15s linear infinite;
    }

    .right {
        animation: marquee-img-right 15s linear infinite;

    }

    @keyframes marquee-img-left {
        0% {
            transform: translateX(-200vw);
        }

        100% {
            transform: translateX(-100vw);
        }
    }

    @keyframes marquee-img-right {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(-100vw);
        }
    }
}