.loyalty {

    .NFT {
        margin-bottom: 10%;

        ul {
            display: flex;
            flex-direction: column;
            align-items: center;

            li {
                width: 80%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .picture {
                    width: 40%;

                    img {
                        width: 100%;
                    }
                }

                .main {
                    margin: 20px 0;
                    width: 50%;


                    p {
                        margin: 20px 0;
                        line-height: 2;
                        text-align: justify;
                    }

                    .title {
                        font-size: 4rem;
                    }
                }
            }

            li:nth-child(2n+1) {

                .picture {
                    order: 2;
                }

                .main {
                    order: 1;
                }
            }

        }
    }

    .purse {

        margin-bottom: 15%;
        display: flex;
        justify-content: center;

        .wrapper {
            width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
                padding: 50px;
                align-self: flex-start;
            }

            ul {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;

                li {
                    width: 30%;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;

                    .main {
                        margin: 20px 0;

                        p {
                            margin: 20px 0;
                            line-height: 2;
                        }
                    }
                }

                .pic {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .picture {
                        width: 100%;

                        img {
                            width: 100%;
                        }
                    }
                }

            }
        }

    }

}

@media screen and (max-width:768px) {
    .loyalty {
        .NFT {
            margin-bottom: 10%;

            ul {
                display: flex;
                flex-direction: column;
                align-items: center;

                li {
                    width: 80%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .picture {
                        width: 100%;
                        height: 35vh;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            width: 100%;
                        }
                    }

                    .main {
                        margin: 20px 0;

                        width: 100%;

                        p {
                            margin: 20px 0;
                            line-height: 2;
                        }

                        .f5 {
                            font-size: 2.5rem;
                        }
                    }
                }

                li:nth-child(2n+1) {

                    .picture {
                        order: 1;
                    }

                    .main {
                        order: 2;
                    }
                }

            }
        }

        .purse {
            .wrapper {
                width: 100%;

                .title {
                    p {
                        font-size: 3rem;
                    }
                }

                ul {
                    width: 100%;
                    flex-direction: column;
                    align-items: center;

                    li {
                        width: 60%;
                        padding: 30px;
                        border-radius: 20px;
                        border: 1px solid $grey--three;
                        margin-bottom: 30px;
                    }

                    li:last-child {
                        border: none;
                    }
                }
            }

        }
    }
}