footer {

    padding: 63px;
    display: flex;
    justify-content: center;

    background-color: #000000;

    position: relative;
    // z-index: 2;

    .copyright {
        position: fixed;
        bottom: 160px;
        left: -150px;
        z-index: 2;
        color: $grey--one;
        transform: rotate(-90deg);

        font-size: 12px;
        font-weight: 350;
        letter-spacing: 1.2px;
        line-height: 2;
    }

    .wrapper {
        width: 100%;
        max-width: 1210px;
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .contact-us {
            margin: 0px 0px 40px;
            font-family: 'Bebas Neue', sans-serif;
            font-size: 118px;
            font-weight: 400;
            letter-spacing: 11.8px;
        }

        section {
            width: 100%;
            display: flex;

            .info {
                min-width: 320px;
                margin-right: 5%;

                p {
                    text-align: left;
                    margin-bottom: 17px;
                    letter-spacing: 1.5px;

                    span {
                        margin-right: 30px;
                        letter-spacing: 1.5px;

                    }
                }
            }

            .map {
                display: flex;
                flex-wrap: wrap;

                ul {
                    width: 120px;
                    font-family: 'Noto Sans TC', sans-serif;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    margin-bottom: 50px;

                    .title {
                        color: #FFF;
                        font-size: 17px;
                        font-weight: 350;
                        letter-spacing: 1.7px;
                        padding: 0px 10px;
                        cursor: pointer;

                        &:hover {
                            color: $grey--two;
                        }

                        a {
                            color: $white;

                            &:hover {
                                color: $grey--two;
                            }
                        }

                    }

                    hr {
                        width: 100%;
                        // min-width: 50px;
                        margin-bottom: 14px;
                        border: 1px solid $grey--two;
                    }

                    li {
                        text-align: left;
                        font-size: 15px;
                        font-weight: 300;
                        letter-spacing: 3px;
                        padding: 5px 10px;

                        cursor: pointer;

                        &:hover {
                            color: $grey--two;
                        }
                    }
                }
            }

        }
    }
}

@media screen and (max-width: 768px) {
    footer {
        padding: 50px;

        .copyright {
            position: static;
            transform: rotate(0deg);
            z-index: 0;

        }



        .wrapper {
            .contact-us {
                font-size: 56px;
                letter-spacing: 5.6px;
            }

            section {
                display: flex;
                flex-direction: column-reverse;


                .info {
                    min-width: 320px;

                    p {
                        text-align: left;
                        margin-bottom: 17px;


                        font-size: 10px;

                        font-weight: 350;
                        letter-spacing: 1px;
                    }
                }

                .map {
                    display: flex;
                    flex-wrap: wrap;

                    ul {
                        width: 120px;
                        font-family: 'Noto Sans TC', sans-serif;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: 50px;
                        padding: 0;
                        margin-right: 20px;

                        .title {
                            font-size: 12px;
                            letter-spacing: 1.2px;

                        }

                        li {
                            font-size: 10px;
                            letter-spacing: 1px;
                        }
                    }
                }

            }
        }
    }
}