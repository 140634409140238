.core-technology {
    .banner {
        padding: 0px 60px;

        h3 {
            color: $white;
            font-family: 'Noto Sans TC';
            font-size: 2.75rem;
            font-weight: 700;
            letter-spacing: 0.5rem;
            margin-bottom: 15px;
        }

        h4 {
            color: $white--one;
            font-family: 'Noto Sans TC';
            font-size: 1.5rem;
            font-weight: 300;
            letter-spacing: 0.3rem;
        }

        .line {
            width: 1px;
            height: 4.5rem;
            border-left: 1px solid $white--one ;
            transform: rotate(15deg);
            margin: 30px;
        }

        p {
            margin: 0 10px;
            color: $white--one;
            font-family: 'Noto Sans TC';
            font-size: 1.5rem;
            font-weight: 300;
            line-height: 30px;
            /* 176.471% */
            letter-spacing: 0.2rem;
        }

        .cube {
            margin-top: 180px;
            margin-bottom: 80px;
        }
    }

    .tool-area {
        background-image: url(../../../../public/img/background/gradient-4.png);
        background-size: cover;
        background-position: center center;
        width: 100%;
        height: 100vh;

        display: flex;
        justify-content: center;
        align-items: center;



        .wrapper {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                width: 40%;
                color: $white--one;
                font-family: 'Noto Sans TC';
                font-size: 1.5rem;
                font-weight: 300;
                line-height: 2.5rem;
                /* 162.5% */
                letter-spacing: 0.3rem;
            }

            // 裝飾
            .left-dec {
                position: relative;
                padding: 3px;
                // outline: 1px solid red;

                .infinity {
                    position: absolute;
                    top: -92px;
                    left: -222px;

                }

                .pyramid {
                    position: absolute;
                    top: -184px;
                    left: -123px;


                }

                .hexagon {
                    position: absolute;
                    top: -9px;
                    left: -117px;
                }
            }

            .right-dec {
                // outline: 1px solid blue;
                padding: 3px;
                position: relative;

                .beta-sheet {
                    position: absolute;
                    top: -175px;
                    right: -119px;
                }

                .cube {
                    position: absolute;
                    top: -97px;
                    right: -287px;
                }

                .wave {
                    position: absolute;
                    top: -31px;
                    right: -142px;
                }
            }



        }



    }

    .enviroment {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 100px 0px;

        div {
            display: flex;
            justify-content: space-around;

            width: 80%;
            gap: 30px;
            margin-top: 15px;
            margin-bottom: 60px;

            .web {
                width: 50%;
                padding: 5%;

                border-radius: 30px;
                background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.02) 100%);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                img {
                    width: 100%;
                }

                p {
                    width: 100%;
                    color: $white--one;
                    font-family: 'Noto Sans TC';
                    font-size: 1rem;
                    font-weight: 300;
                    line-height: 1.6;
                    /* 176.471% */
                    letter-spacing: 0.2rem;

                    text-align: justify;

                    span {
                        color: $orange;
                    }
                }
            }

            .large {
                width: 100%;

                p {
                    width: 100%;
                }
            }
        }


    }
}

@media screen and (max-width:768px) {
    .core-technology {
        .enviroment {
            div {

                display: flex;
                flex-direction: column;
                align-items: center;

                .web {
                    width: 80%;
                }

            }
        }
    }
}