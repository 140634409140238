.home-wrapper {

    background: $black;

    width: 100vw;

    position: relative;

    font-family: 'Noto Sans TC', sans-serif;


    overflow: hidden;

    &::before {
        content: '';
        width: 1px;
        height: 100vh;
        background-color: $white--one;
        display: block;

        position: fixed;
        left: 50px;

    }

    &::after {
        content: '';
        width: 1px;
        height: 100vh;
        background-color: $white--one;
        display: block;

        position: fixed;
        top: 0;
        right: 50px;

    }



    .dec-green {
        width: 30%;
        position: fixed;
        // top: -20%;
        // left: -10%;
        z-index: 0;
        transform: translate(-30%, -25%);

        // animation: rotate 18s infinite linear; 
    }

    .dec-orange {
        width: 30%;
        position: fixed;
        // bottom: 0;
        top: 63%;
        right: 0;
        // top: 580px;
        // right: -90px;
        z-index: 0;
        // transform: translate(30%, 25%);

        // animation: rotate 8s infinite linear;
    }

    @keyframes rotate {
        0% {
            transform: rotateZ(0deg);
            transform-origin: 1500px 1500px;
        }

        100% {
            transform: rotateZ(360deg);
            transform-origin: 1500px 1500px;

        }

    }

    .more {
        font-family: 'Bebas Neue', sans-serif;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5%;
        color: $white;

        span {
            font-size: 2.25rem;
            letter-spacing: 0.225rem;
            margin-right: 14px;
        }

        a {
            font-size: 2.25rem;
            letter-spacing: 0.225rem;
            margin-right: 14px;

            &:hover {
                color: $orange;
            }
        }

        &:hover {
            color: $orange;
        }
    }

    .banner {
        // outline: 1px solid tomato;

        // min-height: 80vh;
        position: relative;
        padding-top: 10%;
        padding-bottom: 10%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;

        // .banner-logo {
        //     position: relative;
        //     font-family: sans-serif;
        //     text-transform: uppercase;
        //     font-size: 2em;
        //     letter-spacing: 4px;
        //     overflow: hidden;
        //     background: linear-gradient(90deg, #000, #fff, #000);
        //     background-repeat: no-repeat;
        //     background-size: 80%;
        //     animation: flash-light 8s linear infinite;
        //     background-clip: text;
        //     -webkit-background-clip: text;
        //     -webkit-text-fill-color: rgba(255, 255, 255, 0);


        // }

        .banner-logo {
            margin: 5% 0;
            // height: 380px;
            height: 244px;
            // fill: transparent;
            // color: rgba($color: $black, $alpha: 0.4);
            // color: $white;
            color: $black;
            // fill: rgba($color: white, $alpha: 0);
            background: linear-gradient(90deg, $black, #fff, $black);
            background-repeat: no-repeat;
            background-size: 80%;

            overflow: hidden;

            animation: flash-light 10s linear infinite;
        }

        @keyframes flash-light {
            0% {
                background-position: -500%;
            }

            100% {
                background-position: 500%;
            }

        }

        // 打字
        @keyframes typing {
            from {
                width: 0
            }
        }

        @keyframes caret {
            50% {
                border-right-color: transparent;
            }
        }

        .banner-typing {
            color: $white;
            font-size: 2rem;

            /* 	width: 8.25em; */
            width: 415px;
            white-space: nowrap;
            overflow: hidden;
            border-right: .05em solid;
            animation: typing 4s steps(13)infinite,
                caret 0.307s steps(1) infinite;

            span {
                display: inline-block;
                width: 126px;
                letter-spacing: 0.6rem;
                padding-left: 4px;

            }
        }


        .onoff {
            display: block;
            width: 25px;
            height: 72px;
            border: 1px solid $white;
            border-radius: 15px;

            position: relative;

            .circle {
                display: block;
                width: 13px;
                height: 13px;
                border-radius: 50%;
                background-color: $grey--one;
                position: absolute;
                top: 10px;
                left: 50%;
                transform: translateX(-50%);

                animation: slide-down 2s ease-in-out infinite;
            }

            @keyframes slide-down {
                0% {
                    top: 10px;
                }

                100% {
                    top: 50px;
                }
            }
        }

        .social-media {
            height: 200px;
            // outline: 1px solid tomato;
            position: absolute;
            bottom: 30vh;
            right: 10px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            color: $white;

            svg {
                &:hover {
                    opacity: 0.4;
                }
            }
        }

    }

    .rev-x {
        // outline: 1px solid red;
        background-image: url('../../../../public/img/blue-black.png');
        background-position: center bottom;
        background-size: cover;

        min-height: 100vh;

        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        overflow: hidden;

        .rev-x-logo {
            width: 60%;
            max-width: 500px;
            margin-top: 200px;
            opacity: 0;
        }

        .fade-in {
            transition: all 1s linear;
            opacity: 1;
        }

        h2 {
            color: $white;
            font-size: 2rem;
            font-weight: 500;
            letter-spacing: 0.4rem;

        }

        p {
            color: $white--one;
            font-size: 1rem;
            font-weight: 300;
            letter-spacing: 0.2rem;

            line-height: 2;
            text-align: center;
        }

        .more {
            font-family: 'Bebas Neue', sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 100px;
            color: $white;

            span {
                font-size: 2.25rem;
                letter-spacing: 0.225rem;
                margin-right: 14px;
            }

            &:hover {
                color: $orange;
            }
        }

        .phone1 {
            width: 20%;
            max-width: 405px;
            position: absolute;
            top: 0;
            right: 5%;
            transform: translateY(-50%);
        }

        .phone2 {
            width: 20%;
            max-width: 415px;
            position: absolute;
            bottom: 0;
            left: 10%;
            transform: translateY(50%);
        }



    }

    // 跑馬燈
    .what-we-do {
        height: 100vh;

        .marquee-container {
            margin-top: 100px;
            width: 100%;
            overflow: hidden;

            .marquee-content {


                display: flex;
                white-space: nowrap;
                animation: marquee 10s linear infinite;
                // transform: translateX(-1600px);

                svg {
                    margin-right: 100px;
                }
            }

            @keyframes marquee {
                0% {
                    transform: translateX(0);
                }

                100% {
                    transform: translateX(-1672px);
                }
            }
        }

        .main {
            width: 100%;
            height: 100vh;
            position: absolute;

            .service {
                border-radius: 30px;

                background-position: center;
                background-size: cover;

                position: absolute;

                display: flex;
                justify-content: flex-start;
                align-items: flex-end;

                color: $white;
                font-size: 1.5rem;
                font-weight: 700;
                letter-spacing: 0.3rem;

                .text-wrapper {
                    background: linear-gradient(180deg, rgba(2, 33, 36, 0.00) 0%, #021B24 50%);

                    padding: 20px;
                    width: 100%;
                    height: 100px;
                    border-radius: 30px;
                    text-align: left;
                    line-height: 2;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    span {

                        display: none;
                        color: rgba(255, 255, 255, 0.60);

                        font-size: 1.25rem;
                        font-weight: 300;
                        letter-spacing: 0.25rem;
                    }

                    .line {
                        opacity: 0;
                        width: 0px;
                        border: 1px solid $white;
                        position: absolute;
                        bottom: 30px;

                    }

                }


                &:hover {
                    .text-wrapper {
                        transition: 500ms linear;
                        height: 80%;
                        opacity: 0.8;
                        justify-content: center;
                        align-items: center;

                        position: relative;

                        span {
                            display: block;
                        }

                        .line {
                            opacity: 1;
                            transition: 500ms linear;
                            width: 100px;
                            border: 1px solid $white;
                            position: absolute;
                            bottom: 30px;

                        }
                    }
                }

            }

            .web3 {
                width: 30%;
                height: 60vh;

                top: -100px;
                left: 15%;
            }

            .CRM-member {
                width: 25%;
                height: 30vh;

                top: -50px;
                right: 25%;
            }

            .ticket {
                width: 25%;
                height: 30vh;

                top: 30vh;
                right: 23%;
            }
        }
    }

    .why-us {

        display: flex;
        flex-direction: column;

        .wrapper {
            background-image: url(../../../../public/img/background/gradient-1.png);
            background-size: cover;
            width: 90%;

            align-self: flex-end;
            position: relative;


            display: flex;
            justify-content: center;
            padding: 10% 0px;

            .title {
                position: absolute;
                top: -10%;
                left: -3%;
                transform: translateX(100vw);
                color: $blue--one;
                opacity: 0.2;

                font-family: 'Bebas Neue', sans-serif;
                font-size: 10rem;
                text-align: left;
                letter-spacing: 1rem;
            }

            .translate {
                transition: all 1.5s linear;
                transform: translate(0);
            }

            ul {

                display: flex;
                flex-direction: column;

                li {
                    display: flex;
                    align-items: center;
                    margin-top: 9%;

                    img {
                        width: 20%;
                    }

                    .main {
                        margin-left: 10%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        color: $white;

                        h4 {
                            font-size: 1.75rem;
                            font-weight: 500;
                        }

                        p {
                            margin-top: 11px;
                            font-size: 1rem;
                            font-weight: 400;
                        }
                    }
                }
            }

        }
    }

    .news {
        padding: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        ul {
            width: 80%;
            color: $white--one;

            li {
                border-bottom: 1px solid #888;
                display: flex;
                align-items: center;
                padding: 1% 0%;

                .title {
                    border: 1px solid $white--one ;
                    padding: 1% 3%;

                }

                .news-title {
                    flex-grow: 1;
                    max-width: 35%;
                }

                p {
                    margin-right: 5%;
                    letter-spacing: 0.2rem;

                }

                .arrow {
                    width: 15%;
                    min-width: 25px;
                    display: flex;
                    justify-content: flex-end;

                    div {
                        width: 100%;
                        border-top: 1px solid $white--one ;
                    }

                    &::after {
                        content: '';
                        width: 6px;
                        height: 6px;
                        border: solid $white--one;
                        border-width: 1px 1px 0 0;
                        transform: translate(-90%, -50%) rotate(45deg);

                    }
                }

                &:hover {
                    color: $blue;
                    transition: 500ms linear;

                    .title {
                        transition: 500ms linear;
                        background-color: $blue;
                        color: $black;
                        border: 1px solid $blue ;

                    }

                    .arrow {
                        width: 15%;

                        div {
                            width: 25%;
                            transition: 500ms linear;
                            border-top: 1px solid $white--one ;
                        }

                    }
                }
            }
        }

        .news-decoration {
            position: absolute;
            top: 10%;
            right: 5%;
            font-family: 'Bebas Neue', sans-serif;
            font-size: 10rem;
            letter-spacing: 16.8px;

            color: transparent;
            -webkit-text-stroke: 1px $blue--one;
            text-stroke: 1px $blue--one;
            opacity: 0.4;


            transform: rotate(90deg);
        }
    }


}

@media screen and (max-width:1600px) {
    .news-decoration {
        display: none;
    }
}

@media screen and (max-width:1200px) {
    .home-wrapper {
        .banner {

            .banner-logo {
                scale: 0.6;
            }

            .banner-typing {
                width: 306px;

                span {
                    width: 92px;
                    letter-spacing: 0.49rem;
                    padding-left: 0;
                }
            }
        }

        .rev-x {
            min-height: 80vh;
        }
    }
}

@media screen and (max-width:768px) {
    .home-wrapper {

        &::before {
            content: '';
            width: 1px;
            height: 100vh;
            background-color: $white--one;
            display: block;

            position: fixed;
            left: 10px;

        }

        &::after {
            content: '';
            width: 1px;
            height: 100vh;
            background-color: $white--one;
            display: block;

            position: fixed;
            top: 0;
            right: 10px;

        }

        .more {
            span {
                font-size: 1rem;
                letter-spacing: 1.6px;
                margin-right: 3px;
            }

            svg {
                height: 18px;
            }
        }

        .banner {
            .banner-logo {
                scale: 0.3;

            }

            .banner-typing {

                width: 253px;

                span {
                    width: 76px;
                }
            }

            .social-media {
                height: 100px;
                // outline: 1px solid tomato;
                position: absolute;
                bottom: 25px;
                right: 10px;

                display: flex;
                flex-direction: column;
                justify-content: space-between;

                color: $white;

                svg {
                    scale: 0.5;

                    &:hover {
                        opacity: 0.4;
                    }
                }
            }

        }

        .rev-x {

            .more {
                margin-top: 20px;
                margin-bottom: 20px;
            }

            .phone1 {
                display: none;
            }

            .phone2 {
                width: 40%;
                max-width: 415px;
                position: static;

            }

        }

        // 跑馬燈
        .what-we-do {
            height: auto;

            .marquee-container {
                margin-top: 50px;

                .marquee-content {
                    // transform: translateX(-1000px);

                    svg {
                        margin-right: 20px;
                        scale: 0.5;
                    }
                }

                @keyframes marquee {
                    0% {
                        transform: translateX(0);
                    }

                    100% {
                        transform: translateX(-1000px);
                    }
                }
            }

            .main {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;

                .service {
                    width: 90%;
                    height: 180px;
                    border-radius: 30px;
                    margin-bottom: 20px;

                    position: static;
                }
            }
        }

        .why-us {
            .wrapper {
                ul {
                    li {
                        .main {
                            margin-left: 0%;
                            padding: 8%;
                        }
                    }
                }
            }
        }

        .news {
            padding: 50px 0;


            ul {
                li {
                    .title {
                        padding: 0px;
                        min-width: 55px;
                        text-align: center;
                    }

                    .news-title {
                        flex-grow: 1;
                        max-width: 40%;
                    }
                }
            }
        }
    }
}