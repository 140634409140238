.slider-container {

    ul {
        // height: 100vh;

        li {
            display: flex;
            flex-direction: column;
            align-items: center;

            section {

                padding: 50px;
                display: flex;
                justify-content: center;
                align-items: center;


                .timeline {
                    margin-right: 8%;
                    position: relative;

                    .star {
                        position: absolute;
                        transform: translateX(-50%);
                        animation: show 500ms ease-in-out;

                    }

                    .vertical-line {
                        height: 400px;
                        border-left: 1px solid $blue--two;
                    }

                }

                .content {
                    width: 80%;
                    display: flex;
                    justify-content: space-between;

                    .main {
                        width: 35vw;
                        display: flex;
                        flex-direction: column;
                        animation: show 1s ease-in;


                        h1 {
                            color: $white;
                            font-family: 'Bebas Neue';
                            font-size: 7.5rem;
                            font-weight: 400;
                            letter-spacing: 0.75rem;
                        }

                        h2 {
                            color: $orange;
                            font-family: 'Noto Sans TC';
                            font-size: 2.2rem;
                            font-weight: 500;
                            letter-spacing: 0.4rem;
                        }

                        p {
                            width: 100%;

                            margin-top: 50px;
                            color: $white--one;
                            font-family: 'Noto Sans TC';
                            font-size: 17px;
                            font-weight: 300;
                            letter-spacing: 3.4px;
                        }
                    }

                    @keyframes show {

                        0% {
                            opacity: 0.7;
                        }

                        30% {
                            opacity: 0.7;
                        }

                        100% {
                            opacity: 1;
                        }

                    }

                    .img-area {
                        position: relative;
                        width: 40%;

                        img {
                            width: 100%;
                        }

                        .man {
                            position: absolute;
                            top: 5%;
                            left: 10%;
                        }
                    }
                }
            }

            .prev-next {
                width: 250px;
                position: relative;
                margin-top: 20px;

                .arrow-left {
                    width: 100px;
                    display: flex;
                    justify-content: flex-end;

                    position: absolute;
                    top: 0;
                    left: 0;

                    div {
                        width: 60px;
                        border-top: 2px solid $white--one ;
                    }

                    &::before {
                        content: '';
                        width: 10px;
                        height: 10px;
                        border: solid $white--one;
                        border-width: 2px 2px 0 0;
                        transform: translate(90%, -50%) rotate(225deg);

                    }
                }

                .arrow-right {
                    width: 100px;
                    display: flex;
                    justify-content: flex-end;
                    position: absolute;
                    top: 0;
                    right: 0;

                    div {
                        width: 60px;
                        border-top: 2px solid $white--one ;
                    }

                    &::after {
                        content: '';
                        width: 10px;
                        height: 10px;
                        border: solid $white--one;
                        border-width: 2px 2px 0 0;
                        transform: translate(-90%, -50%) rotate(45deg);

                    }
                }
            }
        }
    }
}

@media screen and (max-width:768px) {
    .slider-container {
        ul {
            li {
                section {
                    .content {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .main {
                            width: 100%;
                            width: 60vw;

                            h1 {
                                font-size: 5.5rem;
                            }

                            h2 {
                                min-height: 80px;
                                font-size: 2rem;
                                letter-spacing: 0.2rem;
                            }

                            p {
                                min-height: 150px;
                                margin-top: 0px;
                            }
                        }

                        .img-area {
                            padding: 30px;
                            position: relative;

                        }
                    }
                }
            }
        }
    }
}